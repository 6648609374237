import * as React from 'react';
import { BuilderComponent } from '@builder.io/react';
import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Skeleton from '@material-ui/lab/Skeleton';

const defaultDescription = 'Edit this in your entry for a better SEO';
const defaultTitle = 'Olokoo Q2M';

function BlogArticle({ pageContext: { article } }) {
  article = article.content;
  
  var publishedDate = article.data.date;
  var jsDate = new Date(publishedDate);
  publishedDate = jsDate.toLocaleString('default', { dateStyle: 'long' });
   
  return (
    <>
      <Helmet>
        <title>{(article && article.data.title) || defaultTitle}</title>
        <meta
          name="description"
          content={
            (article && article.data.description) || defaultDescription
          }
        />
      </Helmet>
      <div className="article-page container-wrapper">
        <div className="container-content">
          <h3 className="article-published-date">
            <span className="date-title">Blog</span>
            <span className="date-published-on">{`Published On ${publishedDate}`}</span>
          </h3>
          <h1 className="article-name">
            {article && article.name}
          </h1>
          <div className="article-blurb">
            {article && article.data.blurb}
          </div>
          <div className="article-main-image-container">
            <Skeleton className='lazy-load-image-skeleton' variant="rectangular" />
            <LazyLoadImage
              src={article.data.image}
              effect="opacity"
              className="main-image"
              wrapperClassName="main-image-wrapper"
            />
          </div>
          <div className="article-content-container">
            <BuilderComponent
              renderLink={Link}
              name="blog-article"
              content={article}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogArticle;